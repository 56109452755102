import { connect } from 'react-redux';
import {
  clearState,
  setLoading,
  setCheckoutSuccess,
  setPaymentInformation,
  setPaymentMethod,
} from '../../store/actions/checkout';

import PaymentMethods from '../../components/wrappers/PaymentMethods';

const mapStateToProps = (state) => ({
  paymentMethods: state.checkout.paymentMethods,
  paymentMethod: state.checkout.paymentMethod,
  grandTotal: state.checkout.cart.grandTotal,
  emailAddress: state.customer.form.emailAddress,
  billingAddress: state.customer.form.billingAddress,
  loginStatus: state.customer.loginStatus,
  savedCards: state.checkout.savedCards,
  cartData: state.checkout.cart,
});

const mapDispatchToProps = (dispatch) => ({
  setPaymentMethod: (paymentMethod) => dispatch(setPaymentMethod(paymentMethod)),
  setPaymentInformation:
  (paymentInformation) => dispatch(setPaymentInformation(paymentInformation)),
  clearState: () => dispatch(clearState()),
  setLoading: (loading) => dispatch(setLoading(loading)),
  setCheckoutSuccess: (checkoutSuccess) => dispatch(setCheckoutSuccess(checkoutSuccess)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentMethods);
