import React, {Suspense, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from '../Steps/style.module.scss';
import methodStyles from './style.module.scss';
import setDataLayer from '../../../helpers/DataLayer';
import PaymentIcons from '../../common/PaymentIcons';
const BraintreeCard = React.lazy(() => import(/* webpackChunkName: "braintree-card" */ '../../common/PaymentMethod/BraintreeCard'));
const PayPal = React.lazy(() => import('../../common/PaymentMethod/PayPal'));
const GooglePay = React.lazy(() => import('../../common/PaymentMethod/GooglePay'));
const ApplePay = React.lazy(() => import('../../common/PaymentMethod/ApplePay'));
const BraintreeVault = React.lazy(() => import('../../common/PaymentMethod/BraintreeVault'));
const NoPaymentRequired = React.lazy(() => import('../../common/PaymentMethod/NoPaymentRequired'));

const PaymentMethods = (props) => {
  const {
    currentStep,
    steps,
    paymentMethods,
    paymentMethod,
    setPaymentMethod,
    grandTotal,
    emailAddress,
    billingAddress,
    clearState,
    setLoading,
    setCheckoutSuccess,
    savedCards,
    loginStatus,
    cartData
  } = props;


  const {
    success: loggedIn,
  } = loginStatus;

  const { order } = steps;
  const thisStepIndex = order.findIndex((step) => step.name === 'paymentMethods');
  const isReady = (currentStep === thisStepIndex);

  const paymentMethodsList = (methods) => {
    let newMethods = paymentMethods.filter((method) => method.code !== 'braintree_paypal_credit' && method.code !== 'braintree_paypal_vault' && method.code !== 'braintree_paypal_paylater');

    if (window && !window.ApplePaySession) {
      newMethods = newMethods.filter((method) => method.code !== 'braintree_applepay');
    }

    const haveFree = methods.filter((method) => method.code === 'free')[0];

    if (haveFree) {
      return [
        haveFree,
      ];
    }

    if (!loggedIn) {
      newMethods = newMethods.filter((method) => method.code !== 'braintree_cc_vault');
    }

    return newMethods;
  };

  const checkActive = (method) => (method.code === paymentMethod.code);

  const icons = (methodCode) => {
    let paymentIcons = [];

    switch (methodCode) {
      case 'braintree':
        paymentIcons = ['visa', 'mastercard'];
        break;
      case 'braintree_paypal':
        paymentIcons = ['paypal'];
        break;
      case 'braintree_googlepay':
        paymentIcons = ['googlepay'];
        break;
      case 'braintree_applepay':
        paymentIcons = ['applepay'];
        break;
      default:
        break;
    }

    return paymentIcons;
  };


  //checking the number of the step and changing the option of the datalayer to be the chosen payment code
  useEffect(() => {
    if(isReady){
        setDataLayer(cartData, currentStep, paymentMethod.code);
    }
  }, [currentStep, paymentMethod]);

  return (
    <>
      <div className={styles.step__edit}>
        <div className={styles.step__inner}>
          <ul className={methodStyles.paymentMethods}>
            {paymentMethodsList && paymentMethodsList(paymentMethods).map((method) => (
              <li
                key={method.code}
                className={
                    (paymentMethod.code === method.code)
                      ? methodStyles.methodActive : methodStyles.paymentMethod
                }
              >
                <div className={methodStyles.paymentMethod}>
                  <input
                    type="radio"
                    name="paymentMethods"
                    checked={checkActive(method)}
                    onChange={() => setPaymentMethod(method)}
                  />
                  <h6>
                    {method.title}
                  </h6>
                  <PaymentIcons icons={icons(method.code)} />
                </div>
                {(paymentMethod.code === method.code) && (
                  <Suspense fallback={<div>Loading...</div>}>
                    {(method.code === 'braintree') && (
                      <BraintreeCard
                        setLoading={setLoading}
                        grandTotal={grandTotal}
                        paymentMethod={paymentMethod}
                        emailAddress={emailAddress}
                        billingAddress={billingAddress}
                        setCheckoutSuccess={setCheckoutSuccess}
                        loggedIn={loggedIn}
                      />
                    )}
                    {(method.code === 'braintree_paypal') && (
                      <PayPal
                        setLoading={setLoading}
                        grandTotal={grandTotal}
                        paymentMethod={paymentMethod}
                        emailAddress={emailAddress}
                        billingAddress={billingAddress}
                        clearState={clearState}
                        setCheckoutSuccess={setCheckoutSuccess}
                      />
                    )}
                    {(method.code === 'braintree_googlepay') && (
                      <GooglePay
                        setLoading={setLoading}
                        grandTotal={grandTotal}
                        paymentMethod={paymentMethod}
                        emailAddress={emailAddress}
                        billingAddress={billingAddress}
                        clearState={clearState}
                        setCheckoutSuccess={setCheckoutSuccess}
                        expressPayment={false}
                      />
                    )}
                    {(method.code === 'braintree_applepay') && (
                      <ApplePay
                        setLoading={setLoading}
                        grandTotal={grandTotal}
                        paymentMethod={paymentMethod}
                        emailAddress={emailAddress}
                        billingAddress={billingAddress}
                        clearState={clearState}
                        setCheckoutSuccess={setCheckoutSuccess}
                      />
                    )}
                    {(method.code === 'free') && (
                      <NoPaymentRequired
                        setLoading={setLoading}
                        paymentMethod={paymentMethod}
                        emailAddress={emailAddress}
                        billingAddress={billingAddress}
                        clearState={clearState}
                        setCheckoutSuccess={setCheckoutSuccess}
                      />
                    )}
                    {(method.code === 'braintree_cc_vault' && loggedIn) && (
                      <BraintreeVault
                        setLoading={setLoading}
                        grandTotal={grandTotal}
                        paymentMethod={paymentMethod}
                        emailAddress={emailAddress}
                        billingAddress={billingAddress}
                        clearState={clearState}
                        setCheckoutSuccess={setCheckoutSuccess}
                        savedCards={savedCards}
                      />
                    )}
                  </Suspense>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

PaymentMethods.propTypes = {
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  paymentMethod: PropTypes.shape({
    code: PropTypes.string,
  }),
  grandTotal: PropTypes.number,
  billingAddress: PropTypes.shape({}),
  emailAddress: PropTypes.string,
  shippingMethod: PropTypes.shape({
    method_code: PropTypes.string,
    carrier_code: PropTypes.string,
    method_title: PropTypes.string,
    carrier_title: PropTypes.string,
  }),
  setPaymentMethod: PropTypes.func,
  setLoading: PropTypes.func,
  clearState: PropTypes.func,
  savedCards: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.string,
      public_hash: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  loginStatus: PropTypes.shape({
    success: PropTypes.bool,
    message: PropTypes.string,
  }),
};

PaymentMethods.defaultProps = {
  paymentMethods: [],
  paymentMethod: {
    code: '',
  },
  grandTotal: 0,
  billingAddress: {},
  emailAddress: '',
  shippingMethod: {},
  setPaymentMethod: () => {},
  setLoading: () => {},
  clearState: () => {},
  savedCards: [],
  loginStatus: {
    success: false,
    message: '',
  },
};

export default PaymentMethods;
